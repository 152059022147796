import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: "#ECEFF0",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
  },
  app: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  containerWeb: {
    width: "100%",
    maxWidth: "1400px",
    padding: "40px",
    height: "93%",
    [theme.breakpoints.down("lg")]: {
      height: "100%",
      padding: "20px 30px",
    },
    "& .header": {
      fontSize: 22,
      margin: "15px 5px 10px",
      fontWeight: 500,
    },
    "& .MuiGrid-item": {
      padding: 0,
    },
  },
  container: {
    minWidth: "1400px",
    padding: 25,
    "& .header": {
      fontSize: 22,
      margin: "15px 0",
      fontWeight: 500,
    },
  },
  viewWeb: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    borderRadius: 35,
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.08)",
    width: "90%",
    maxWidth: "1400px",
    maxHeight: "830px",
  },
  view: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    borderRadius: 35,
    // width: "max-content",
  },
  wrapper: {
    maxWidth: "800px",
    borderRadius: 30,
    padding: 10,
    "& .header": {
      fontSize: 20,
      fontWeight: 500,
      margin: "10px 0",
    },
    backgroundColor: "#fff",
  },
  order: {
    paddingRight: "40px !important",
    [theme.breakpoints.down("lg")]: {
      padding: "20px 20px 20px 10px !important",
    },
  },
  boxItem: {
    borderRadius: 20,
    boxShadow: "-1px 1px 10px 2px rgb(244,244,244)",
    margin: "15px 0 10px",
    padding: "15px 30px",
    [theme.breakpoints.down("lg")]: {
      padding: 15,
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    lineHeight: 1.5,
    // minHeight: "160px",
    fontSize: 15,
    "& .name": {
      fontWeight: 500,
      fontSize: 18,
    },
    "& .value": {
      color: "gray",
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .info": {
      marginLeft: 30,
      [theme.breakpoints.down("lg")]: {
        marginLeft: 20,
      },
    },
    "& .capitalize": {
      textTransform: "capitalize",
      paddingLeft: 8,
    },
    "& .icon": {
      width: 70,
    },
    "& .red": {
      color: "red",
    },
    "& .black": {
      color: "black",
    },
    "& .gray": {
      color: "gray",
      paddingRight: 8,
    },
    "& .uppercase": {
      fontWeight: 600,
      fontSize: 15,
      paddingBottom: "5px",
      textTransform: "uppercase",
    },
    "& .license": {
      textTransform: "uppercase",
    },
    "& .boxImage": {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
      borderRadius: 15,
      maxWidth: "85px",
      padding: 10,
      marginRight: 30,
      [theme.breakpoints.down("lg")]: {
        marginRight: 15,
      },
    },
    "& .w-100": {
      width: "100%"
    }
  },
  method: {
    height: "fit-content",
    padding: "20px 30px !important",
    marginTop: "20px",
    borderRadius: 35,
    backgroundColor: "#565CBA",
    color: "#fff",
    [theme.breakpoints.down("lg")]: {
      padding: "10px 20px !important",
    },
    "& .header": {
      fontSize: 22,
      fontWeight: 500,
    },
    "& .divider": {
      background: "#6268C5",
      height: "1.5px",
      margin: "15px 0",
    }
  },
  boxCard: {
    margin: "25px 0",
    "& .MuiGrid-root": {
      // marginLeft: "0px !important",
    },
    "& .MuiGrid-container": {
      marginLeft: "4px !important",
    },
    // "& :first-child": {
    //   textAlign: "start",
    // },
    // "& :last-child": {
    //   textAlign: "end",
    // },
    "& .card": {
      border: "1.5px solid #6268C5",
      borderRadius: 5,
      padding: 8,
      textAlign: "center",
      minWidth: "-webkit-fill-available",
      display: "inline-block",
      "&:hover": {
        cursor: "pointer",
        border: "1.5px solid #fff",
      },
    },
    "& .border": {
      border: "1.5px solid #fff",
    },
    "& .checkIcon": {
      display: "flex",
      justifyContent: "end",
      height: "15px",
    },
    "& .none": {
      visibility: "hidden",
    },
    "& .center": {
      textAlign: "center",
      color: "#fff",
      fontSize: 17,
      fontWeight: 500,
      marginTop: 25,
    },
    "& .img": {
      width: 40,
      height: 40,
      display: "inline-flex",
    },
  },

  boxCardApp: {
    marginTop: 12,
    "& .card": {
      border: "1px solid #fff",
      borderRadius: 5,
      padding: 10,
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
        border: "2px solid #25806D",
      },
    },
    "& .border": {
      border: "2px solid #25806D",
    },
    "& .checkIcon": {
      display: "flex",
      justifyContent: "end",
      color: "#25806D",
    },
    "& .none": {
      visibility: "hidden",
    },
    "& .center": {
      textAlign: "center",
      color: "#25806D",
      fontSize: 18,
      fontWeight: 500,
    },
    "& .img": {
      width: 45,
      height: 45,
      display: "inline-flex",
    },
  },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0",
    "& .price": {
      display: "flex",
      flexDirection: "row",
      fontSize: 18,
      fontWeight: 700,
      fontSize: 15
    },
    "& .vnd": {
      fontSize: 12,
      paddingLeft: 5
    },
    "& .total": {
      display: "flex",
      flexDirection: "row",
      fontSize: 20,
    },
    "& .value": {
      fontWeight: "bold",
      textTransform: "uppercase",
      textAlign: "right",
    },
    "& .border": {
      padding: "5px 10px",
      width: "60%",
      border: "1px solid #25806D",
      borderRadius: 5,
      backgroundColor: "#F1F1F1",
      textAlign: "right",
    },
  },
  btnRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
    "& .vnd": {
      fontSize: 12,
      paddingLeft: 5
    },
    "& .total": {
      display: "flex",
      flexDirection: "row",
      fontSize: 20,
    },
  },
  payBtn: {
    display: "flex",
    justifyContent: "center",
    margin: "25px 0 15px 0",
    "& .text": {
      fontSize: 18,
    },
    "& .MuiButton-root": {
      backgroundColor: "#4EE1C1",
      width: "90%",
      textTransform: "none",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontWeight: 600,
      fontSize: 18,
      padding: "0 25px",
      lineHeight: 1,
      borderRadius: 12,
      "&:hover": {
        backgroundColor: "#4dddbc ",
      },
    },
    "& .label": {
      fontSize: 18,
      fontWeight: 600,
      padding: "15px 0",
    },
  },

  empty: { height: 50 },
  list: { maxHeight: "430px", overflow: "auto", paddingRight: "5px" },
  boxItems: {
    borderRadius: 20,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    margin: "16px 0",
    padding: 10,
    fontSize: 13,
    lineHeight: 1.5,
    minHeight: "120px",
    display: "flex",
    alignItems: "center",
    "& .name": {
      fontWeight: 600,
      fontSize: 15,
      paddingBottom: "5px",
    },
    "& .info": {
      marginLeft: 10,
      width: "100%",
      overflow: "auto",
    },
    "& .bold": {
      fontWeight: 600,
      fontSize: 14,
    },
    "& .capitalize": {
      textTransform: "capitalize",
      paddingLeft: 8,
    },
    "& .uppercase": {
      fontWeight: 600,
      fontSize: 15,
      paddingBottom: "5px",
      textTransform: "uppercase",
    },
    "& .license": {
      textTransform: "uppercase",
    },
    "& .icon": {
      width: "50px",
    },
    "& .gray": {
      color: "gray",
      paddingRight: 8,
      fontWeight: 400
    },
    "& .red": {
      color: "red",
    },
    "& .black": {
      color: "black",
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      // alignItems: "flex-end",
      width: "100%",
    },
    "& .vnd": {
      fontSize: 12,
      paddingLeft: 5,
      color: "gray",
    },
    "& .itemRow": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      width: "100%",
      fontWeight: 500
    },
    "& .price": {
      fontSize: 10,
      color: "gray",
    },
    "& .boxImage": {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
      borderRadius: 15,
      maxWidth: "65px",
      padding: 8,
      marginRight: 5,
    },
    "& .code": {
      fontSize: 20,
      fontWeight: 800,
    },
  },
  hotline: {
    display: "inline-flex",
    flexDirection: "row",
    margin: "10px 0",
    background: "red",
    textDecoration: "underline",
    borderRadius: 16,
    padding: "5px 15px",
    color: "#fff",
    "& .phone": {
      color: "#fff",
      marginLeft: "5px",
      fontSize: 16,
      fontWeight: "bold",
    },
  },
  info: {
    padding: 10,
    "& .title": {
      color: "#25806D",
      fontWeight: "bold",
    },
    "& .italic": {
      fontStyle: "italic",
      fontSize: 13,
    },
    "& .red": {
      color: "#dc3545",
      marginTop: "-5px",
    },
  },

  qrBox: {
    display: "flex",
    justifyContent: "center",
    "& .qr": {
      margin: 15,
      padding: "15px 0 0",
      border: "5px solid #25806D",
      width: "30%",
      cursor: "pointer",
      textAlign: "center",
    },
    "& .app": {
      width: "40%",
    },
    "& .btn": {
      backgroundColor: "#25806D",
      textAlign: "center",
      color: "#fff",
      fontSize: 15,
      padding: 5,
    },
  },
  notiBox: {
    marginBottom: 20,
    padding: 10,
    border: "1px solid #25806D",
    borderRadius: 5,
    "& .ul": {
      margin: "8px 0",
      paddingLeft: 15,
      lineHeight: 1.5,
    },

    "& .title": {
      color: "#25806D",
      fontStyle: "italic",
      fontWeight: "bold",
    },
    "& .value": {
      color: "#dc3545",
    },
    "& .underline": {
      textDecoration: "underline",
    },
  },
  button: {
    position: "sticky",
    bottom: 0,
    left: 0,
    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.15)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 12,
    background: "#fff",
    borderRadius: "16px 16px 0px 0px",
    padding: "15px 10px",
    maxWidth: "800px",
  },
  confirmBtn: {
    backgroundColor: "#ffffff",
    width: "100%",
    height: "100%",
    color: "#009B4B",
    borderRadius: 7,
    padding: "8px 0",
    fontSize: 15,
    fontWeight: 400,
    border:'1px solid #009B4B',
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    margin: "10px 0",
    "&:hover": {
      backgroundColor: "#25806D ",
    },
  },
  centerItem: {
    textAlign: "center",
    marginBottom: 30,
  },
  centerBox: {
    maxWidth: "900px",
    textAlign: "center",
    padding: 20,
    "& .content": {
      padding: "10px 0",
      lineHeight: "1.5",
    },
    "& .color": {
      color: "#25806D",
    },
    "& .empty": {
      height: "50px",
    },
  },

  failBox: {
    maxWidth: "800px",
    margin:'0px auto',
    textAlign: "center",
    padding: "10px 10%",
    background: "#fff",
    "& .content": {
      padding: "5px 0",
      lineHeight: "1.5",
    },
    "& .color": {
      color: "#25806D",
    },
    "& .empty": {
      height: "30px",
    },
  },
  btnPayBack:{
    backgroundColor: "#009B4B",
    width: "100%",
    height: "100%",
    padding: "10px",
    color: "#ffffff",
    marginBottom: 10,
    borderRadius: 7,
    fontSize: 16,
    // fontWeight: 600,
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    border:'1px solid #009B4B',
    "&:hover": {
      backgroundColor: "#25806D ",
    },
  },
  failBtn: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "100%",
    padding: "10px",
    color: "#009B4B",
    borderRadius: 7,
    fontSize: 16,
    // fontWeight: 600,
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    border:'1px solid #009B4B',
    "&:hover": {
      backgroundColor: "#25806D ",
    },
  },
  listOrderApp: {
    overflowY: "auto",
    padding: "0 8px",
  },
  listOrder: {
    overflowY: "auto",
    maxHeight: "730px",
    height: "80vh",
    padding: "0 8px",
  }
}));
